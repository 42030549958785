/*
@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
*/

.georgetown-style {
  color: red;
}

.top-sticky {
  position: fixed; /* sticky; */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.top-sticky + div {
  padding-top: 65px;
}


div.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

.accordion-title {
  background-color: hsl(0, 0%, 86%);
  cursor: pointer;
}

.accordion-content {
  background-color: hsl(0, 0%, 98%);
}

.grad-red {
  background: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(255, 0, 0, 1));
}



.result-title-color {
  background-color: #416975 !important;;
  color: #FDFDFD !important;;
}

.eligibility-color {
  background-color: #FDFDFD !important;;
}

.chance-color {
  background-color: #FDFDFD !important;;
}

.explanation-color {
  background-color: #416975 !important;;
  color: #FDFDFD !important;;
}

.benefit-color { 
  color: #9EC185 !important;;
  
}

.benefit-background-color {
  background-color:#FDFDFD !important;;
}

.risk-color {
  background-color: #FDFDFD !important;;
}

/* for center element, at least used in the banner */

.v-centered-container {
  white-space: nowrap; 
}

.v-centered-container:after {
  content: ''; 
  display: inline-block; 
  height: 100%; 
  vertical-align: middle;
  width: 0;
}

.v-centered-child {
  display: inline-block; 
  vertical-align: middle; 
  white-space: normal; 
}


/* original */


.my-link {
  color:#8cb0ea !important;
}

.my-link:hover {
  color:#FFF  !important;
}
